import * as React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    paddingLeft: "0.75rem",
    paddingRight: "0.5rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    backgroundColor: theme.palette.common.blackFour,
  },

  productNameText: { lineHeight: "1rem" },

  loadingBox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "0.5rem",
  },
}))

const StockOptionItem = ({ option, user, dispatchFeedback, setProducts }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const classes = useStyles()

  const handleChange = event => {
    setIsLoading(true)
    axios
      .put(
        process.env.GATSBY_STRAPI_URL + `/api/options-datas/${option.id}`,
        {
          data: {
            inStock: !option.inStock,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(res => {
        axios
          .get(process.env.GATSBY_STRAPI_URL + "/api/section/get-all-stock", {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          })
          .then(response => {
            setIsLoading(false)
            //////////////////////TEMP ORDER FIX
            let productsCopy = [...response.data]
            productsCopy.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id)
            })
            for (let i = 0; i < productsCopy.length; i++) {
              productsCopy[i].sharedFields.sort(function (a, b) {
                return parseInt(a.id) - parseInt(b.id)
              })
              for (let j = 0; j < productsCopy[i].sharedFields.length; j++) {
                productsCopy[i].sharedFields[j].optionsData.sort(function (
                  a,
                  b
                ) {
                  return parseInt(a.id) - parseInt(b.id)
                })
              }
            }
            //////////////////////TEMP ORDER FIX

            setProducts(productsCopy)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      classes={{ root: classes.mainContainer }}
      wrap="nowrap"
    >
      <Grid item>
        <Typography variant="body1" classes={{ root: classes.productNameText }}>
          {option.optionPrintLabel}
        </Typography>
      </Grid>

      <Grid item>
        {isLoading ? (
          <Box className={classes.loadingBox}>
            <CircularProgress color="inherit" size={"1rem"} />
          </Box>
        ) : (
          <Switch
            checked={option.inStock}
            size="small"
            onChange={handleChange}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default StockOptionItem
