import * as React from "react"
import axios from "axios"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Switch from "@material-ui/core/Switch"
import Divider from "@material-ui/core/Divider"
import Chip from "@material-ui/core/Chip"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import StockOptionItem from "./stockOptionItem"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    backgroundColor: theme.palette.common.blackThree,
    paddingLeft: "0.25rem",
    paddingRight: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    marginBottom: "0.5rem",
    borderRadius: "0.5rem",
  },

  mainContainerSelected: {
    backgroundColor: theme.palette.common.blackTweleve,
    color: `${theme.palette.common.black} !important`,
  },
  moreIcon: {
    padding: "0.5rem",
    marginRight: "0.5rem",
  },

  moreIconMoreVis: {
    color: `${theme.palette.common.black} !important`,
  },
  moreIconDisabled: {
    color: `${theme.palette.common.blackFour} !important`,
  },
  moreBox: {
    backgroundColor: theme.palette.common.blackEight,
    borderRadius: "0.5rem",
    maxHeight: 0,
    transition: "all 0.25s",
    marginBottom: 0,
    overflow: "hidden",
    padding: 0,
  },
  moreBoxVis: {
    maxHeight: "50rem",
    overflowY: "scroll",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    paddingTop: "0.75rem",

    marginBottom: "0.75rem",
    transition: "all 0.25s",
  },
  productNameText: {
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "1.2rem",
  },

  outOfStockCountText: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1rem",
  },

  fieldDigitalLabel: {
    marginTop: "0.1rem",
    fontSize: "1rem",
    lineHeight: "1rem",
    fontWeight: 600,
  },
  divider: {
    height: "1px",
  },
  fieldBox: {
    backgroundColor: theme.palette.common.blackFour,
    marginBottom: "0.75rem",
    paddingBottom: "0.5rem",
    borderRadius: "0.5rem",
  },
  fieldBoxTit: {
    backgroundColor: theme.palette.common.black,
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    height: "2.75rem",
    marginBottom: "0.5rem",
    borderTopRightRadius: "0.5rem",
    borderTopLeftRadius: "0.5rem",
  },

  loadingBox: {
    paddingTop: "0.5rem",
    paddingBottom: 0,
    paddingRight: "1.2rem",
    paddingLeft: "1.2rem",
  },

  chip: {
    height: "1.75rem",
  },
  fieldBoxTitGrid: {
    height: "100%",
  },
}))

const StockProductItem = ({ product, user, dispatchFeedback, setProducts }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isMoreVisible, setIsMoreVisible] = React.useState(false)
  const [hasItems, setHasItems] = React.useState(false)
  const [outOfStockCount, setOutOfStockCount] = React.useState(0)
  const [outOfStockCountArray, setOutOfStockCountArray] = React.useState(
    Array(product.sharedFields.length).fill(0)
  )

  React.useEffect(() => {
    let hasItemsCalc = false
    let outOfStock = 0
    let outOfStockArray = Array(product.sharedFields.length).fill(0)
    for (let i = 0; i < product.sharedFields.length; i++) {
      if (product.sharedFields[i].isStockTracked) {
        hasItemsCalc = true
        let outOfStockInItem = 0
        for (let k = 0; k < product.sharedFields[i].optionsData.length; k++) {
          if (!product.sharedFields[i].optionsData[k].inStock) {
            outOfStock++
            outOfStockInItem++
          }
        }
        outOfStockArray[i] = outOfStockInItem
      }
    }
    setOutOfStockCount(outOfStock)
    setHasItems(hasItemsCalc)
    setOutOfStockCountArray(outOfStockArray.slice(0).reverse())
  }, [product])

  const handleChange = event => {
    setIsLoading(true)
    axios
      .put(
        process.env.GATSBY_STRAPI_URL + `/api/products/${product.id}`,
        {
          data: {
            inStock: !product.inStock,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(res => {
        axios
          .get(process.env.GATSBY_STRAPI_URL + "/api/section/get-all-stock", {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          })
          .then(response => {
            setIsLoading(false)
            //////////////////////TEMP ORDER FIX
            let productsCopy = [...response.data]
            productsCopy.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id)
            })
            for (let i = 0; i < productsCopy.length; i++) {
              productsCopy[i].sharedFields.sort(function (a, b) {
                return parseInt(a.id) - parseInt(b.id)
              })
              for (let j = 0; j < productsCopy[i].sharedFields.length; j++) {
                productsCopy[i].sharedFields[j].optionsData.sort(function (
                  a,
                  b
                ) {
                  return parseInt(a.id) - parseInt(b.id)
                })
              }
            }
            //////////////////////TEMP ORDER FIX

            setProducts(productsCopy)
          })
          .catch(err => {
            console.error(err)
            setIsLoading(false)
            handleError(err, dispatchFeedback)
          })
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        classes={{
          root: clsx(classes.mainContainer, {
            [classes.mainContainerSelected]: isMoreVisible,
          }),
        }}
      >
        <Grid
          item
          xs
          onClick={e => {
            if (hasItems) {
              setIsMoreVisible(!isMoreVisible)
            }
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <IconButton
                disabled={!hasItems}
                classes={{
                  root: clsx(classes.moreIcon, {
                    [classes.moreIconMoreVis]: isMoreVisible,
                  }),
                  disabled: classes.moreIconDisabled,
                }}
                onClick={e => {
                  setIsMoreVisible(!isMoreVisible)
                }}
              >
                {isMoreVisible ? (
                  <ExpandLessOutlinedIcon />
                ) : (
                  <ExpandMoreOutlinedIcon />
                )}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                classes={{ root: classes.productNameText }}
              >
                {product.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              {outOfStockCount > 0 ? (
                <Chip
                  size={"small"}
                  classes={{ root: classes.chip }}
                  icon={<ErrorOutlineOutlinedIcon />}
                  label={
                    <Typography
                      variant="body1"
                      classes={{ root: classes.outOfStockCountText }}
                    >
                      {`${outOfStockCount}`}
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Box className={classes.loadingBox}>
                  <CircularProgress color="inherit" size={"1.25rem"} />
                </Box>
              ) : (
                <Switch checked={product.inStock} onChange={handleChange} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMoreVisible ? (
        <Box
          classes={{
            root: clsx(classes.moreBox, {
              [classes.moreBoxVis]: isMoreVisible,
            }),
          }}
        >
          {product.sharedFields
            .slice(0)
            .reverse()
            .map((field, index) => {
              if (field.isStockTracked) {
                return (
                  <Box key={index} classes={{ root: classes.fieldBox }}>
                    <Box key={index} classes={{ root: classes.fieldBoxTit }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        classes={{ root: classes.fieldBoxTitGrid }}
                      >
                        <Grid item>
                          <Typography
                            variant="body1"
                            classes={{ root: classes.fieldDigitalLabel }}
                          >
                            {field.fieldPrintLabel}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {outOfStockCountArray[index] > 0 ? (
                            <Chip
                              size={"small"}
                              classes={{ root: classes.chip }}
                              icon={<ErrorOutlineOutlinedIcon />}
                              label={
                                <Typography
                                  variant="body1"
                                  classes={{
                                    root: classes.outOfStockCountText,
                                  }}
                                >
                                  {`${outOfStockCountArray[index]}`}
                                </Typography>
                              }
                            />
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    {field.optionsData.map((option, ind) => {
                      return (
                        <Box key={ind}>
                          <StockOptionItem
                            option={option}
                            user={user}
                            dispatchFeedback={dispatchFeedback}
                            setProducts={setProducts}
                          ></StockOptionItem>
                          {field.optionsData.length - 1 !== ind ? (
                            <Divider
                              classes={{ root: classes.divider }}
                            ></Divider>
                          ) : (
                            <></>
                          )}
                        </Box>
                      )
                    })}
                  </Box>
                )
              } else {
                return null
              }
            })}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default StockProductItem
