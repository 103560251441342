import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import SectionSwitch from "./common/sectionSwitch"
import StockProductItem from "./stockProductItem"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  box: {},
  topContainer: {
    backgroundColor: theme.palette.common.black,
    paddingLeft: "1.0rem",
    paddingRight: "1.0rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderTopLeftRadius: "0.5rem !important",
    borderTopRightRadius: "0.5rem !important",
    width: "100%",
  },

  bottomContainer: {
    borderBottomLeftRadius: "0.5rem !important",
    borderBottomRightRadius: "0.5rem !important",
    backgroundColor: theme.palette.common.blackTwo,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.01rem",
    width: "100%",
  },
}))

const StockSection = ({
  user,
  kitchenSettings,
  dispatchFeedback,
  storeSettings,
  products,
  slug,
  section,
  setProducts,
}) => {
  const classes = useStyles()
  const [allInStock, setAllInStock] = React.useState(null)

  React.useEffect(() => {
    let allInStockCalc = true
    let allOutOfStockCalc = true
    for (let i = 0; i < products.length; i++) {
      if (products[i].section.slug === slug) {
        if (products[i].inStock) {
          allOutOfStockCalc = false
        } else {
          allInStockCalc = false
        }
      }
    }

    if (!allInStockCalc && !allOutOfStockCalc) {
      setAllInStock(null)
    } else if (allInStockCalc) {
      setAllInStock("YES")
    } else if (allOutOfStockCalc) {
      setAllInStock("NO")
    }
  }, [products, slug])

  return (
    <Box classes={{ root: classes.box }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        classes={{ root: classes.topContainer }}
      >
        <Grid item>
          <Typography variant="h6">{section.name}</Typography>
          <Typography variant="body1">{"Section"}</Typography>
        </Grid>
        <Grid item>
          <SectionSwitch
            user={user}
            allInStock={allInStock}
            slug={slug}
            products={products}
            setProducts={setProducts}
            dispatchFeedback={dispatchFeedback}
          />
        </Grid>
      </Grid>
      <Box classes={{ root: classes.bottomContainer }}>
        {products.length > 0
          ? products.map((product, index) => {
              if (product.section.slug === slug) {
                return (
                  <StockProductItem
                    key={index}
                    product={product}
                    user={user}
                    dispatchFeedback={dispatchFeedback}
                    setProducts={setProducts}
                  />
                )
              } else {
                return null
              }
            })
          : null}
      </Box>
    </Box>
  )
}

export default StockSection
