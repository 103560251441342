import React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import handleError from "../../../../helperFunc/handleError"

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"

import { makeStyles, withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  titleText: {
    fontWeight: 500,
    marginTop: "0.5rem",
    lineHeight: "1rem",
    fontSize: "1rem",
    textAlign: "center",
  },
  paper: {
    backgroundColor: `${theme.palette.common.blackTwo} !important`,
    display: "inline-block",
    flexWrap: "wrap",
    borderRadius: "1.5rem",
    minWidth: "9.5rem",

    right: 0,
  },

  loadingBox: {
    paddingTop: "0.65rem",
    paddingBottom: "0.15rem",
    paddingRight: "0.5rem",
  },

  alertTitle: {
    fontSize: "1.75rem !important",
    marginTop: "0.5rem",
    lineHeight: "2rem !important",
  },

  dialogText: {
    fontSize: "1.25rem !important",
    lineHeight: "1.5rem",
  },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  but: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
}))

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexWrap: "wrap",
    marginTop: "0.25rem",
    marginLeft: "0.5rem",
  },
  grouped: {
    border: "none",

    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
      marginRight: "0.5rem",
    },
  },
}))(ToggleButtonGroup)

const StyledToggleButtonOn = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    borderTopRightRadius: "2rem !important",
    borderBottomRightRadius: "2rem !important",
    padding: "1rem",
    height: "2.25rem",
    width: "2.25rem",
  },
  selected: {
    backgroundColor: `${theme.palette.common.midGreen} !important`,
  },
}))(ToggleButton)

const StyledToggleButtonOff = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    borderTopLeftRadius: "2rem !important",
    borderBottomLeftRadius: "2rem !important",
    padding: "1rem",
    height: "2.25rem",
    width: "2.25rem",
  },
  selected: {
    backgroundColor: `${theme.palette.common.redSelected} !important`,
  },
}))(ToggleButton)

export default function SectionSwitch({
  user,
  dispatchFeedback,
  slug,
  allInStock,
  products,
  setProducts,
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [requstedState, setRequstedState] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleSelectedChange = (e, change) => {
    if (change !== null) {
      setOpen(true)
      setRequstedState(change)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setRequstedState(null)
  }

  const handleChange = () => {
    setOpen(false)
    setIsLoading(true)

    axios
      .put(
        process.env.GATSBY_STRAPI_URL + "/api/section/set-section-stock",
        {
          sectionSlug: slug,
          stateTo: requstedState,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setIsLoading(false)
        //////////////////////TEMP ORDER FIX
        let productsCopy = [...response.data]
        productsCopy.sort(function (a, b) {
          return parseInt(a.id) - parseInt(b.id)
        })
        for (let i = 0; i < productsCopy.length; i++) {
          productsCopy[i].sharedFields.sort(function (a, b) {
            return parseInt(a.id) - parseInt(b.id)
          })
          for (let j = 0; j < productsCopy[i].sharedFields.length; j++) {
            productsCopy[i].sharedFields[j].optionsData.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id)
            })
          }
        }
        //////////////////////TEMP ORDER FIX

        setProducts(productsCopy)
        setRequstedState(null)
      })
      .catch(error => {
        setIsLoading(false)
        setRequstedState(null)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }

  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <Box className={classes.loadingBox}>
          <CircularProgress color="inherit" size={"1.5rem"} />
        </Box>
      ) : (
        <StyledToggleButtonGroup
          value={allInStock}
          exclusive
          onChange={handleSelectedChange}
        >
          <StyledToggleButtonOff size="large" value={"NO"}>
            <ClearOutlinedIcon />
          </StyledToggleButtonOff>
          <StyledToggleButtonOn size="large" value={"YES"}>
            <DoneOutlinedIcon />
          </StyledToggleButtonOn>
        </StyledToggleButtonGroup>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby={`change-ss-${slug}-title`}
        aria-describedby={`change-ss-${slug}-description`}
      >
        <DialogTitle
          id={`change-ss-${slug}-title`}
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>
            {"Change Section Availability "}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{ root: classes.dialogText }}
            id={`change-ss-${slug}-description`}
          >
            {`Are you sure you want to set all the products in the ${
              slug.charAt(0).toUpperCase() + slug.slice(1)
            } Section to ${
              requstedState === "YES" ? "Available" : "Unavailable"
            }?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.but }}
              >
                <Typography variant="body1">{"No"}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleChange}
                variant="contained"
                size="large"
                disableElevation
                fullWidth
                classes={{ root: classes.but }}
              >
                <Typography variant="body1">{"Yes"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
