import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import StockSection from "./stockSection"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
  },

  mainBox: {
    width: "100%",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    marginBottom: "0.5rem",
    paddingTop: "5rem",
  },

  gridBox: { padding: "0.5rem" },

  headingText: {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    overflowWrap: "break-word",
    marginBottom: "0.25rem",
    marginLeft: "0.5rem",
  },
}))

const StockMainInCont = ({
  user,
  kitchenSettings,
  dispatchFeedback,
  storeSettings,
  products,
  setProducts,
}) => {
  const classes = useStyles()

  return (
    <Box classes={{ root: classes.mainBox }}>
      <Typography variant="body1" classes={{ root: classes.headingText }}>
        {"Items Availability"}
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent={"space-between"}
        alignItems="flex-start"
        classes={{ root: classes.mainContainer }}
      >
        {kitchenSettings.sections.map((section, index) => {
          return (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              classes={{ root: classes.gridBox }}
            >
              <StockSection
                user={user}
                kitchenSettings={kitchenSettings}
                dispatchFeedback={dispatchFeedback}
                storeSettings={storeSettings}
                products={products}
                slug={section.slug}
                section={section}
                setProducts={setProducts}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default StockMainInCont
